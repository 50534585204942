import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { isNeedToAbortJoinFlowAfterLogin } from './isNeedToAbortJoinFlowAfterLogin';
import { createParticipant } from './createParticipant';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';
import { payForChallenge } from './payForChallenge';
import {
  getTimeZone,
  getUserType,
  navigateToThankYouPage,
  promptLogin,
  toServerStartDate,
} from './userContextHelpers';
import { getParticipant } from './getParticipant';
import userTypeHandlers from './userTypeHandlers';

export async function joinToChallenge(
  flowAPI: ControllerFlowAPI,
  selectedPaymentOption: SelectedPaymentOption,
  startDate?: string,
) {
  const { controllerConfig } = flowAPI;
  const startDateFormatted = toServerStartDate(startDate);
  const timeZone = getTimeZone(flowAPI);

  controllerConfig.setProps({
    isLoading: true,
  });

  if (!flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn) {
    try {
      await promptLogin(controllerConfig);
    } catch (e) {
      controllerConfig.setProps({
        isLoading: false,
      });
      return;
    }

    if (await isNeedToAbortJoinFlowAfterLogin(flowAPI)) {
      controllerConfig.setProps({
        isLoading: false,
      });
      return;
    }
  }

  const currentParticipant = await getParticipant(flowAPI);
  const currentParticipantUserType = getUserType(
    { loggedIn: true },
    currentParticipant,
  );

  if (userTypeHandlers.isJoinedAlready(currentParticipantUserType)) {
    controllerConfig.setProps({
      isLoading: false,
    });
    return;
  }

  const participant = await createParticipant({
    timeZone,
    startDateFormatted,
    flowAPI,
    selectedPaymentOption,
  });

  if (participant) {
    switch (participant.transitions[0].state) {
      case ParticipantState.PAYMENT_REQUESTED:
      case ParticipantState.PAYMENT_STARTED:
        const { userJoined } = await payForChallenge(
          flowAPI,
          selectedPaymentOption,
          participant.id,
          startDateFormatted,
          timeZone,
        );
        if (userJoined) {
          return navigateToThankYouPage(flowAPI);
        }
        break;
      case ParticipantState.JOIN_REQUESTED:
      case ParticipantState.JOINED:
        return navigateToThankYouPage(flowAPI);
      default:
        console.error('[challenge] Unexpected participant state during join');
    }
  }
  console.error('[CHALLENGE] Unable to join user');
}
