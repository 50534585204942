import {
  ILocation,
  IWidgetControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import { ILocationProviderProps } from './LocationProvider';
import { PageSlugs, ILocationContext } from './LocationContext';
import { VIEW_MODE } from '../../config/constants';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import pickBy from 'lodash/pickBy';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { isUUID } from '../../selectors/isUUID';

export interface ILocationProps extends ILocationContext {}

export function _getChallengeIdFromLocation(flowAPI: ControllerFlowAPI) {
  const location = locationProviderPropsMap(flowAPI);
  const generalInfo = generalDataProviderPropsMap(flowAPI);

  if (generalInfo.viewMode === VIEW_MODE.Preview) {
    return location.query.challengeId;
  }

  return location.path[location.path.length - 1];
}

export function getChallengeIdFromLocation(flowAPI: ControllerFlowAPI) {
  const challengeId = _getChallengeIdFromLocation(flowAPI);
  return isUUID(challengeId) ? challengeId : null;
}

export function locationProviderPropsMap(
  flowAPI: ControllerFlowAPI,
): ILocationProviderProps {
  const location = (flowAPI.controllerConfig.wixCodeApi.location || {
    path: [],
    query: {},
  }) as ILocation;
  const generalInfo = generalDataProviderPropsMap(flowAPI);
  const query = location.query;

  return {
    location,
    path: [...location.path],
    query: {
      ...query,
    },
    goToPage: async (options) => {
      const isApplyOverrides =
        Object.keys(query).includes('editorScriptUrlOverride') ||
        Object.keys(query).includes('tpaWidgetUrlOverride');
      const overrides = isApplyOverrides
        ? pickBy(
            flowAPI.controllerConfig.wixCodeApi.location.query,
            (value, key) => key.toLowerCase().includes('override'),
          )
        : null;

      if (generalInfo.viewMode === VIEW_MODE.Preview) {
        // @ts-ignore
        // tslint:disable-next-line
        await flowAPI.controllerConfig.wixCodeApi.location.navigateTo(
          {
            pageId: PageSlugs[options.pageId],
            state: options.challengeId,
            queryParams: { challengeId: options.challengeId, ...overrides },
          },
          // @ts-ignore
          (err) => {
            console.error(err);
          },
        );
      } else {
        const isAbsoluteURL = !!options.isAbsoluteURL;
        const overridesAsUrlPart = isApplyOverrides
          ? Object.keys(overrides)
              .map((key) => `${key}=${encodeURIComponent(overrides[key])}`)
              .join('&')
          : null;

        const {
          url,
          relativeUrl,
        } = await flowAPI.controllerConfig.wixCodeApi.site.getSectionUrl({
          sectionId: options.pageId,
        });

        if (options.challengeId) {
          console.log('[Challenge Navigation]', options.challengeId);
          flowAPI.controllerConfig.wixCodeApi.location.to(
            `${isAbsoluteURL ? url : relativeUrl}/${options.challengeId}` +
              (isApplyOverrides ? '?' + overridesAsUrlPart : ''),
          );
        } else {
          flowAPI.controllerConfig.wixCodeApi.location.to(
            `${isAbsoluteURL ? url : relativeUrl}` +
              (isApplyOverrides ? '?' + overridesAsUrlPart : ''),
          );
        }
      }
    },
    goToExternalUrl(url: string) {
      flowAPI.controllerConfig.wixCodeApi.location.to(url);
    },
  };
}
