import { IChallengeDataProps } from '../../../../../contexts/ChallengeDataProvider/challengeDataProviderPropsMap';
import { IPaidPlansProviderProps } from '../../../../../contexts/PaidPlans/interfaces';
import { IUserProviderProps } from '../../../../../contexts/User/UserProvider';

export enum SelectedPaymentOption {
  SinglePayment = 'SinglePayment',
  PaidPlans = 'PaidPlans',
}

export type IPricingProps = IChallengeDataProps &
  IPaidPlansProviderProps &
  IUserProviderProps & {
    t(key: string): string;
    selectedPaymentOption: SelectedPaymentOption | null;
    onPaymentSelected(selectedPaymentOption: SelectedPaymentOption): void;
    className?: string;
    disabled?: boolean;
  };
