import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { V1Participant } from '@wix/ambassador-challenge-service-web/types';
import { getChallengeIdFromLocation } from '../../Location/locationProviderPropsMap';
import { isUUID } from '../../../selectors/isUUID';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { getUserFromConfig } from './userContextHelpers';
import userTypeHandlers from './userTypeHandlers';

export async function getParticipant(
  flowAPI: ControllerFlowAPI,
): Promise<V1Participant> {
  const user = getUserFromConfig(flowAPI.controllerConfig);
  const challengeId = getChallengeIdFromLocation(flowAPI);

  if (user.loggedIn && isUUID(challengeId)) {
    try {
      const { participants } = await participantAPI.listParticipants({
        challengeId,
        memberId: user.id,
      });

      const activeParticipant = participants.find((participant) => {
        return !userTypeHandlers.isUserLeftOrRejected(
          participant.transitions[0].state,
        );
      });

      if (activeParticipant) {
        return activeParticipant;
      }

      if (participants.length) {
        return participants[0];
      }
    } catch (err) {
      console.error('[Challenges]: Error at get participant request.');
      console.error(err);
    }
  }

  return null;
}
