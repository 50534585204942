import { IInviteLinkProviderProps } from './interfaces';
import { getChallengeIdFromLocation } from '../Location/locationProviderPropsMap';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { isUUID } from '../../selectors/isUUID';
import { getInvitationLink } from '../../selectors/getInvitationLink';

export async function inviteLinkPropsMap({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IInviteLinkProviderProps> {
  const challengeId = getChallengeIdFromLocation(flowAPI);

  try {
    const inviteLink =
      generalDataProviderPropsMap(flowAPI).viewMode === 'Editor' ||
      !isUUID(challengeId)
        ? ''
        : await getInvitationLink(
            challengeId,
            flowAPI.controllerConfig.platformAPIs.bi.metaSiteId,
          );

    return {
      inviteLink,
    };
  } catch (error) {
    console.error(error);
  }

  return {
    inviteLink: '',
  };
}
