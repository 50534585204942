import {
  I$WWrapper,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { settingsProviderPropsMap } from '../../contexts/Settings/settingsProviderPropsMap';
import { initApi } from '../../services/initApi';
import { ScreenNames } from '../../contexts/BI/interfaces';
import { Pages } from '../../contexts/Location/LocationContext';
import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import userTypeHandlers from '../../contexts/User/helpers/userTypeHandlers';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;

  initApi(flowAPI);

  return {
    async pageReady() {
      const initialProps = await providersPropsMap({
        biSettings: {
          screenName: ScreenNames.PaymentPage,
          getItemsCount: () => 1,
        },
        flowAPI,
        settingsConfig: {},
        enabledProviders: ['challengeDataProvider', 'paymentProvider'],
      });

      try {
        flowAPI.controllerConfig.setProps({ ...initialProps });
      } catch (e) {
        console.info(e);
      }

      if (!initialProps.participant) {
        initialProps.goToPage({
          pageId: Pages.Details,
          challengeId: initialProps.challengeData.challenge.id,
        });
        return;
      }
      const currentParticipantState =
        initialProps.participant.transitions[0].state;

      if (!userTypeHandlers.isInPaymentState(currentParticipantState)) {
        initialProps.goToPage({
          pageId: Pages.ThankYou,
          challengeId: initialProps.challengeData.challenge.id,
        });
      }
    },
  };
};

export default createController;
