import { challengeAPI } from '@wix/challenges-web-api/dist/src/API';
import { getChallengeIdFromLocation } from '../Location/locationProviderPropsMap';
import { CHALLENGE, CHALLENGE_EDITORX } from '../../__mocks__/challenge';
import { VIEW_MODE } from '../../config/constants';
import { isAvailableForJoinImmediately } from '../../selectors/challenges';
import {
  GetChallengeResponse,
  V1Challenge,
} from '@wix/ambassador-challenge-service-web/types';
import memoize from 'lodash/memoize';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { isUUID } from '../../selectors/isUUID';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';

export interface IChallengeDataProps {
  challengeData: GetChallengeResponse & {
    isAvailableForJoinImmediately: boolean;
  };
}

export const challengeDataProviderPropsMap = memoize(async function ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IChallengeDataProps> {
  const challengeId = getChallengeIdFromLocation(flowAPI);
  const { viewMode } = flowAPI.controllerConfig.wixCodeApi.window;
  const isEditorX = !!flowAPI.controllerConfig.config?.style?.styleParams
    ?.booleans?.responsive;

  let challengeResponse: GetChallengeResponse = {
    challenge: null,
  };

  try {
    if (viewMode !== 'Editor' && isUUID(challengeId)) {
      challengeResponse = await challengeAPI.getChallenge({
        challengeId,
      });
    }
  } catch (err) {
    console.error(err);
  }

  if (viewMode !== VIEW_MODE.Site && !challengeResponse.challenge) {
    challengeResponse.challenge = (isEditorX
      ? (CHALLENGE_EDITORX as any)
      : (CHALLENGE as any)) as V1Challenge;
  }

  if (viewMode !== VIEW_MODE.Site) {
    const { challengesListData } = await challengesListDataProviderPropsMap({
      flowAPI,
    });

    if (
      challengesListData?.totalCount &&
      // @ts-ignore
      !challengesListData.memberChallenges[0].challenge.mocked === true
    ) {
      challengeResponse.challenge = challengesListData.memberChallenges[0]
        .challenge as V1Challenge;
    }
  }

  return {
    challengeData: {
      ...challengeResponse,
      ...{
        isAvailableForJoinImmediately: isAvailableForJoinImmediately(
          challengeResponse.challenge,
        ),
      },
    },
  };
});
