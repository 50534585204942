import uuid from 'uuid/v4';
import memoize from 'lodash/memoize';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { ResolutionStatus } from '@wix/ambassador-challenge-service-web/types';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { IResolveStepContext } from './ResolveStepContext';
import { getChallengeIdFromLocation } from '../Location/locationProviderPropsMap';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { biProviderPropsMap } from '../BI/BIProviderPropsMap';
import { ActionTypes, ScreenNames } from '../BI/interfaces';

const resolveStep = async (
  flowAPI: ControllerFlowAPI,
  stepId: string,
  complexity: number,
  comment: string,
): Promise<void> => {
  const bi = biProviderPropsMap({
    biSettings: {
      screenName: ScreenNames.ChallengePageForParticipant,
      getItemsCount: () => 1,
    },
    flowAPI,
  });
  const { participant } = await userProviderPropsMap({ flowAPI });
  const feedbackItems = [];

  if (complexity !== null) {
    feedbackItems.push({
      feedbackItemSettingsId: 'rate',
      number: complexity,
    });
  }

  if (comment !== null) {
    feedbackItems.push({
      feedbackItemSettingsId: 'comment',
      text: comment,
    });
  }

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: true,
  });

  const sendResolveRequest = async (actionId) => {
    return participantAPI.resolveStep({
      actionId,
      challengeId: getChallengeIdFromLocation(flowAPI),
      feedback: {
        createdAt: new Date(),
        items: feedbackItems,
      },
      participantId: participant?.id,
      status: ResolutionStatus.COMPLETED,
      stepId,
    });
  };

  try {
    await bi.beforeAndAfterAction(
      ActionTypes.STEP_COMPLETE,
      async (actionId) => {
        return feedbackItems && feedbackItems.length
          ? bi.beforeAndAfterAction(ActionTypes.FEEDBACK_SEND, () => {
              return sendResolveRequest(actionId);
            })
          : sendResolveRequest(actionId);
      },
    );
  } catch (err) {
    console.error('[Challenge]: failed to resolve step:', err);
    flowAPI.reportError(err);
    flowAPI.sentryMonitor.captureMessage(
      `[RESOLVE STEP]: ${JSON.stringify(err)}`,
    );

    flowAPI.controllerConfig.setProps({
      resolveStepError: err.toString(),
    });
  }

  // await new Promise(resolve => setTimeout(resolve, 3000));

  flowAPI.controllerConfig.setProps({
    isResolveStepRequestInProgress: false,
  });
};

export const resolveStepDataProviderPropsMap = memoize(async function ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IResolveStepContext> {
  return {
    isResolveStepRequestInProgress: false,
    resolveStep: async (stepId, complexity, comment) =>
      resolveStep(flowAPI, stepId, complexity, comment),
    resolveStepError: null,
  };
});
