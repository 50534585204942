import {
  IChallengeSettings,
  TextAlignment,
  ImageRatio,
  CropSelection,
} from './challengeSettings.types';

export const challengeSettings: IChallengeSettings = {
  headerTextAlignment: {
    key: 'headerTextAlignment',
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  contentTextAlignment: {
    key: 'contentTextAlignment',
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  imageRatio: {
    key: 'imageRatio',
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  cropSelection: {
    key: 'cropSelection',
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  displayHeaderDate: {
    key: 'displayHeaderDate',
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderPrice: {
    key: 'displayHeaderPrice',
    getDefaultValue() {
      return true;
    },
  },
  displayHeaderImage: {
    key: 'displayHeaderImage',
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeDuration: {
    key: 'displayChallengeDuration',
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeSteps: {
    key: 'displayChallengeSteps',
    getDefaultValue() {
      return true;
    },
  },
  displayChallengeParticipants: {
    key: 'displayChallengeParticipants',
    getDefaultValue() {
      return true;
    },
  },
  displayOwner: {
    key: 'displayOwner',
    getDefaultValue() {
      return true;
    },
  },
  displayOneApp: {
    key: 'displayOneApp',
    getDefaultValue() {
      return true;
    },
  },
  displayDivider: {
    key: 'displayDivider',
    getDefaultValue() {
      return true;
    },
  },
};
