import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import {
  V1Challenge,
  V1AccessType,
} from '@wix/ambassador-challenge-service-web/types';
import format from 'date-fns/format';

import { VIEW_MODE } from '../config/constants';

const getDatesConfig = (
  challenge: V1Challenge,
): { lastMod: string; created: string } => {
  const { transitions } = challenge;
  const lastDate = format(new Date(transitions[0].occurredAt), 'd-MM-yyyy');

  if (transitions.length === 1) {
    return {
      lastMod: lastDate,
      created: lastDate,
    };
  }

  return {
    lastMod: lastDate,
    created: format(
      new Date(transitions[transitions.length - 1].occurredAt),
      'd-M-yyyy',
    ),
  };
};

const getPricingData = (
  challenge: V1Challenge,
): { currency: string; price: string } => {
  const currency = challenge.settings.pricing?.singlePayment?.price?.currency;

  return {
    ...(currency && { currency }),
    price: challenge.settings.pricing?.singlePayment?.price?.amount || '0',
  };
};

const getImageData = (
  challenge: V1Challenge,
): { id: string; height: string; width: string } => {
  const { media } = challenge.settings.description;
  return {
    id: media?.image?.id,
    width: '1000',
    height: '800',
  };
};

export async function renderSeoMetatags(
  flowAPI: ControllerFlowAPI,
  challenge: V1Challenge,
) {
  const { viewMode } = flowAPI.controllerConfig.wixCodeApi.window;

  if (!challenge || viewMode !== VIEW_MODE.Site) {
    return;
  }

  if (
    challenge.settings.accessRestrictions.accessType === V1AccessType.SECRET
  ) {
    return;
  }
  const { wixCodeApi } = flowAPI.controllerConfig;
  const seoAPI = wixCodeApi.seo;
  const { url } = wixCodeApi.location;

  const itemData = {
    id: challenge.id,
    ...getDatesConfig(challenge),
    url,
    name: challenge.settings.description.title,
    description: challenge.settings.description.details,
    registration: {
      availability: challenge.settings.accessRestrictions.accessType,
      ...getPricingData(challenge),
    },
    mainImage: {
      ...getImageData(challenge),
    },
  };

  // tslint:disable-next-line
  await seoAPI.renderSEOTags({
    itemType: 'CHALLANGES_PAGE',
    itemData,
  });
}
