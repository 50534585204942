import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  PaymentType,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';
import { IPromptLogin, IUserType, UserState } from '../UserContext';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { getParticipant } from './getParticipant';
import format from 'date-fns/format';
import { getDateFromString } from '../../../selectors/dates';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import {
  getChallengeIdFromLocation,
  locationProviderPropsMap,
} from '../../Location/locationProviderPropsMap';
import { Pages } from '../../Location/LocationContext';

export const getUserType = (
  user: { loggedIn: boolean },
  participant: V1Participant,
): IUserType => {
  if (!user.loggedIn) {
    return UserState.VISITOR;
  }

  if (!participant) {
    return UserState.MEMBER;
  }

  return participant.transitions[0].state;
};

export async function promptLogin(controllerConfig: IWidgetControllerConfig) {
  await controllerConfig.wixCodeApi.user.promptLogin({
    lang: 'en',
  } as IPromptLogin);
}

export async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    const user = getUserFromConfig(flowAPI.controllerConfig);
    const participant = await getParticipant(flowAPI);

    flowAPI.controllerConfig.setProps({
      user,
      participant,
      userType: getUserType(user, participant),
    });
  });
}

export function getUserFromConfig(config: IWidgetControllerConfig) {
  return {
    ...config.wixCodeApi.user.currentUser,
  };
}

export const toServerStartDate = (startDate?: string) => {
  return startDate ? format(getDateFromString(startDate), 'yyyy-MM-dd') : null;
};

export const getTimeZone = (flowAPI: ControllerFlowAPI) => {
  return typeof Intl === 'object' && typeof Intl.DateTimeFormat === 'function'
    ? Intl.DateTimeFormat().resolvedOptions().timeZone
    : flowAPI.controllerConfig.wixCodeApi.site.timezone;
};

export function getPaymentType(
  selectedPaymentOption: SelectedPaymentOption,
): PaymentType {
  switch (selectedPaymentOption) {
    case SelectedPaymentOption.PaidPlans:
      return PaymentType.PAID_PLANS;
    default:
    case SelectedPaymentOption.SinglePayment:
      return PaymentType.SINGLE_PAYMENT;
  }
}

export function navigateToThankYouPage(flowAPI: ControllerFlowAPI) {
  const location = locationProviderPropsMap(flowAPI);
  return location.goToPage({
    challengeId: getChallengeIdFromLocation(flowAPI),
    pageId: Pages.ThankYou,
  });
}
