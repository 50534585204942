import userTypeHandlers from '../contexts/User/helpers/userTypeHandlers';
import { VIEW_MODE } from '../config/constants';
import { V1Participant } from '@wix/ambassador-challenge-service-web/types';
import { IUserType } from '../contexts/User/UserContext';
import { IWixWindowViewMode } from '@wix/native-components-infra/dist/src/types/types';

export const isParticipantPage = ({
  participant = {},
  userType,
  viewMode,
}: {
  participant: V1Participant;
  userType: IUserType;
  viewMode: IWixWindowViewMode;
}) => {
  return (
    participant?.id &&
    userTypeHandlers.isJoinedAlready(userType) &&
    viewMode === VIEW_MODE.Site
  );
};
