import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { getChallengeIdFromLocation } from '../../Location/locationProviderPropsMap';
import { UserState } from '../UserContext';

export async function leaveTheChallenge(
  flowAPI: ControllerFlowAPI,
  participantId: string,
) {
  const bi = biProviderPropsMap({
    biSettings: {
      screenName: ScreenNames.ChallengePage,
      getItemsCount: () => 1,
    },
    flowAPI,
  });

  await bi.beforeAndAfterAction(
    ActionTypes.LEAVE_THE_CHALLENGE,
    async (actionId) =>
      participantAPI.deleteParticipant({
        actionId,
        challengeId: getChallengeIdFromLocation(flowAPI),
        participantId,
      }),
  );

  flowAPI.controllerConfig.setProps({
    userType: UserState.MEMBER,
    participant: null,
    participantSteps: [],
  });
}
