import { IExperimentsProviderProps } from './interfaces';
import {
  getChallengesExperiments,
  isDisabled,
  isEnabled,
} from '../../services/experiments';
import memoize from 'lodash/memoize';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';

const STORAGE_FIELD = 'CHALLENGES_EXPERIMENTS';

export const experimentsProviderPropsMap = memoize(
  async (flowAPI: ControllerFlowAPI): Promise<IExperimentsProviderProps> => {
    let experiments;

    if (
      flowAPI.controllerConfig.platformAPIs.storage.memory &&
      flowAPI.controllerConfig.platformAPIs.storage.memory.getItem
    ) {
      try {
        experiments = JSON.parse(
          flowAPI.controllerConfig.platformAPIs.storage.memory.getItem(
            STORAGE_FIELD,
          ),
        );
      } catch (e) {
        flowAPI.reportError(e);
        flowAPI.sentryMonitor.captureMessage(
          `[FAILED] experiments get to storage config: ${JSON.stringify(
            flowAPI,
          )}`,
        );
        console.error(
          '[CHALLENGES]: Failed to get storage data for experiments',
        );
      }
    }

    if (!experiments) {
      experiments = await getChallengesExperiments();

      if (
        flowAPI.controllerConfig.platformAPIs.storage.memory &&
        flowAPI.controllerConfig.platformAPIs.storage.memory.setItem
      ) {
        flowAPI.controllerConfig.platformAPIs.storage.memory.setItem(
          STORAGE_FIELD,
          JSON.stringify(experiments),
        );
      }
    }

    return {
      experiments,
      disabled(experiment): boolean {
        return isDisabled(experiments[experiment]);
      },
      enabled: (experiment): boolean => {
        return isEnabled(experiments[experiment]);
      },
    };
  },
);
