import { wrapper } from '@wix/challenges-web-api/dist/src/interfaces/generated/api';

import IChallenge = wrapper.com.wixpress.achievements.api.v1.Challenge;

import AccessType = wrapper.com.wixpress.achievements.api.v1.AccessType;
import DurationUnit = wrapper.com.wixpress.achievements.api.v1.DurationUnit;
import State = wrapper.com.wixpress.achievements.api.v1.Challenge.State;
import ISinglePayment = wrapper.com.wixpress.achievements.api.v1.Challenge.Pricing.SinglePayment;
import IPlanPayment = wrapper.com.wixpress.achievements.api.v1.Challenge.Pricing.PaidPlan;
import ISinglePaymentMethods = wrapper.com.wixpress.achievements.api.v1.Challenge.Pricing.SinglePayment.PaymentMethod;

export const CHALLENGE_NO_STEPS_PARTICIPANTS_ONGOING: IChallenge = {
  id: 'c57dd02d-e79f-470b-b95f-f3be2617502d',
  stepsSummary: {
    stepsNumber: 0,
  },
  owners: [
    {
      id: 'a0a8750a-8dbe-4c75-8b15-54d9ed384fac',
      fullName: 'Tom Bordi',
      imageUrl:
        'https://lh3.googleusercontent.com/a-/AAuE7mCiI4gvELcfLXxETINXNk0oaBM8bzd9jzsWVnyo%3Ds96-c',
    },
  ],
  settings: {
    // @ts-ignore
    rewards: [],
    pricing: {},
    description: {
      title: 'Marathon Training',
      details:
        'Include a general overview of your challenge so that participants know what to expect. Also, tell participants about any prerequisites or special preparation they need to do before the challenge. You can write an inspirational message that motivates participants to give it their all and achieve their goals.',
    },
    timelineType: {
      ongoing: {
        startTime: { year: 2019, month: 11, day: 1 },
        singleTimeZone: 'false',
      },
    },
    accessRestrictions: { accessType: AccessType.PUBLIC, maxParticipants: 0 },
  },
  participantsSummary: {
    participantsNumber: 0,
  },
  transitions: [
    { state: State.PUBLISHED, occurredAt: '2019-11-01T10:47:19.469Z' },
    { state: State.DRAFT, occurredAt: '2019-10-31T16:22:52.633Z' },
  ],
};

export const TIMELINE_TYPE_ONGOING = {
  ongoing: {
    startTime: { year: 2019, month: 11, day: 1 },
    singleTimeZone: 'false',
  },
};

export const TIMELINE_TYPE_FLEXIBLE = {
  flexible: {
    duration: {
      value: 21,
      unit: DurationUnit.DAYS,
    },
  },
};
export const TIMELINE_TYPE_SPECIFIC = {
  specific: {
    duration: {
      value: 21,
      unit: DurationUnit.DAYS,
    },
  },
};

export const SINGLE_PRICE: ISinglePayment = {
  allowedMethods: [ISinglePaymentMethods.OFFLINE_PAYMENT],
  price: {
    currency: 'USD',
    amount: '123.01',
  },
};

export const PAID_PLANS_PRICE: IPlanPayment[] = [
  {
    id: 'f2e85b46-014b-4c66-80f7-477d653014bb',
  },
];

export const CHALLENGE: IChallenge = {
  id: '7374670a-339f-498b-9eaa-38ac2fdaeea8',
  owners: [
    {
      id: 'a0a8750a-8dbe-4c75-8b15-54d9ed384fac',
      fullName: 'Site Owner',
      contactId: '99a65e13-d621-4e49-adf9-46d2fe944a6f',
    },
  ],
  settings: {
    // @ts-ignore
    rewards: [],
    pricing: {},
    description: {
      title: 'Marathon Training',
      details:
        'Include a general overview of your challenge so that participants know what to expect. Also, tell participants about any prerequisites or special preparation they need to do before the challenge. You can write an inspirational message that motivates participants to give it their all and achieve their goals.',
      media: {
        image: {
          id: 'a0a875_94a2a4699c3c4df187c6cda6969855e4~mv2.jpg',
          url: 'media/a0a875_94a2a4699c3c4df187c6cda6969855e4~mv2.jpg',
          height: 1000,
          width: 800,
        },
      },
    },
    timelineType: {
      ongoing: {
        startTime: {
          year: 2019,
          month: 12,
          day: 21,
          hour: 0,
          minute: 0,
        },
        singleTimeZone: 'false',
      },
      flexible: {
        duration: {
          value: 21,
          unit: DurationUnit.DAYS,
        },
      },
    },
    accessRestrictions: {
      accessType: AccessType.PUBLIC,
    },
    completionCriteria: {
      completedSteps: {
        all: {},
      },
      completedMilestones: {
        all: {},
      },
    },
  },
  stepsSummary: {
    stepsNumber: 15,
  },
  participantsSummary: {
    participantsNumber: 10,
    joinRequestsNumber: 0,
    invitationsNumber: 0,
  },
  transitions: [
    {
      state: State.PUBLISHED,
      occurredAt: '2019-12-20T09:13:04.343Z',
    },
    {
      state: State.DRAFT,
      occurredAt: '2019-12-20T09:13:00.923Z',
    },
  ],
};

export const CHALLENGE_EDITORX = {
  ...CHALLENGE,
  settings: {
    ...CHALLENGE.settings,
    description: {
      ...CHALLENGE.settings.description,
      media: {
        image: {
          width: 1200,
          id: 'a0a875_2419a6dfc2a24ee9a003a63038f86dd3~mv2.jpg',
          url: 'media/a0a875_2419a6dfc2a24ee9a003a63038f86dd3~mv2.jpg',
          height: 1200,
        },
      },
    },
  },
};
