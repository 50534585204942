import { IChallengeSettings } from './challengeSettings.types';
import {
  TextAlignment,
  ImageRatio,
  CropSelection,
} from '../../Settings/challengeSettings/challengeSettings.types';

export const challengeSettings: IChallengeSettings = {
  mobHeaderTextAlignment: {
    key: 'mobHeaderTextAlignment',
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  mobContentTextAlignment: {
    key: 'mobContentTextAlignment',
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  mobImageRatio: {
    key: 'mobImageRatio',
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  mobCropSelection: {
    key: 'mobCropSelection',
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  mobDisplayHeaderDate: {
    key: 'mobDisplayHeaderDate',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayHeaderPrice: {
    key: 'mobDisplayHeaderPrice',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayHeaderImage: {
    key: 'mobDisplayHeaderImage',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayChallengeDuration: {
    key: 'mobDisplayChallengeDuration',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayChallengeSteps: {
    key: 'mobDisplayChallengeSteps',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayChallengeParticipants: {
    key: 'mobDisplayChallengeParticipants',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayOwner: {
    key: 'mobDisplayOwner',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayOneApp: {
    key: 'mobDisplayOneApp',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayDivider: {
    key: 'mobDisplayDivider',
    getDefaultValue() {
      return true;
    },
  },
};
