import { approvalAPI } from '@wix/challenges-web-api/dist/src/API';
import { getChallengeIdFromLocation } from '../../Location/locationProviderPropsMap';
import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { ApprovalRequestResolutionStatus } from '@wix/ambassador-challenge-service-web/types';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { getParticipant } from './getParticipant';
import { IUserContext } from '../UserContext';
import { getUserType } from './userContextHelpers';

export async function cancelInvite(flowAPI: ControllerFlowAPI) {
  const approvals = await approvalAPI.listApprovalRequests({
    challengeId: getChallengeIdFromLocation(flowAPI),
    creatorId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
    includeResolved: false,
  });
  const approvalRequestId =
    approvals?.requests['0'] && approvals?.requests['0'].id;

  if (approvalRequestId) {
    const bi = biProviderPropsMap({
      biSettings: {
        screenName: ScreenNames.ChallengePage,
        getItemsCount: () => 1,
      },
      flowAPI,
    });
    await bi.beforeAndAfterAction(ActionTypes.CHALLENGE_JOIN, (actionId) =>
      approvalAPI.resolveApprovalRequest({
        actionId,
        requestId: approvalRequestId,
        resolutionStatus: ApprovalRequestResolutionStatus.CANCELLED,
      }),
    );

    const currentParticipant = await getParticipant(flowAPI);
    const currentParticipantUserType = getUserType(
      { loggedIn: true },
      currentParticipant,
    );

    flowAPI.controllerConfig.setProps({
      participant: currentParticipant,
      userType: currentParticipantUserType,
    } as Pick<IUserContext, 'participant' | 'userType'>);
  } else {
    console.error("[Challenges]: Can't find approval request id.", {
      flowAPI,
    });
    return;
  }
}
