import {
  IChallengeSettings,
  TextAlignment,
  ImageShape,
  ImageRatio,
  CropSelection,
} from './challengeSettings.types';

export const challengeSettings: IChallengeSettings = {
  itemsPerRow: {
    key: 'itemsPerRow',
    getDefaultValue() {
      return 3;
    },
  },
  displayImage: {
    key: 'displayImage',
    getDefaultValue() {
      return true;
    },
  },
  displayDuration: {
    key: 'displayDuration',
    getDefaultValue() {
      return true;
    },
  },
  displayParticipants: {
    key: 'displayParticipants',
    getDefaultValue() {
      return true;
    },
  },
  displayDivider: {
    key: 'displayDivider',
    getDefaultValue() {
      return true;
    },
  },
  displayPrice: {
    key: 'displayPrice',
    getDefaultValue() {
      return true;
    },
  },
  textAlignment: {
    key: 'textAlignment',
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  cardSpacing: {
    key: 'cardSpacing',
    getDefaultValue: () => 32,
  },
  imageShape: {
    key: 'imageShape',
    getDefaultValue() {
      return ImageShape.Rectangle;
    },
  },
  imageRatio: {
    key: 'imageRatio',
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  cropSelection: {
    key: 'cropSelection',
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  buttonText: {
    key: 'buttonText',
    getDefaultValue() {
      return 'challenge-card.view-button';
    },
  },
};
