import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { getChallengeIdFromLocation } from '../../Location/locationProviderPropsMap';
import { userProviderPropsMap } from '../../User/userProviderPropsMap';
import { paidPlansBenefitsApi } from '@wix/challenges-web-api/dist/src/API';
import { APPS } from '../../../config/constants';
import {
  GetEligibleOrdersRequest,
  GetEligibleOrdersResponse,
  MemberOrdersByPlan,
} from '@wix/ambassador-pricing-plan-benefits-server/types';
import { UserState } from '../../User/UserContext';

export async function getUserEligiblePlans(
  flowAPI: ControllerFlowAPI,
  challengeId = getChallengeIdFromLocation(flowAPI),
): Promise<MemberOrdersByPlan[]> {
  const user = await userProviderPropsMap({ flowAPI });
  if (!challengeId || user.userType === UserState.VISITOR) {
    return [];
  }
  try {
    const response: GetEligibleOrdersResponse = await paidPlansBenefitsApi.membersService.getEligibleOrders(
      {
        appDefId: APPS.Prod,
        resourceId: challengeId,
        contactId: user.user.id,
        targetDate: new Date().toISOString(),
      } as GetEligibleOrdersRequest,
    );

    return response.memberOrdersByPlan;
  } catch (e) {
    console.error('[CHALLENGES] getEligibleOrders', e);
    return [];
  }
}
