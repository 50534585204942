import { InitAppForPageFn } from 'yoshi-flow-editor-runtime';

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowAPI,
) => {
  // try {
  //   console.log('');
  // } catch (e) {
  //   flowAPI.reportError(e);
  // }

  return {};
};
