import memoize from 'lodash/memoize';
import { VIEW_MODE } from '../../config/constants';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import {
  ListParticipantStepsResponse,
  ParticipantStepState,
  V1ParticipantStep,
} from '@wix/ambassador-challenge-service-web/types';
import { PARTICIPANT_STEPS } from '../../__mocks__/participantSteps';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { IParticipantStepsContext } from './ParticipantStepsContext';
import { getChallengeIdFromLocation } from '../Location/locationProviderPropsMap';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import userTypeHandlers from '../User/helpers/userTypeHandlers';
import {
  getRightDateFromBackend,
  getWeekRange,
  sureDateAfterSpecificDate,
} from '../../selectors/dates';
import format from 'date-fns/format';
import addDays from 'date-fns/addDays';

function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async () => {
    flowAPI.controllerConfig.setProps({
      participantSteps: await loadParticipantSteps(flowAPI),
    });
  });
}

const loadParticipantSteps = async (
  flowAPI: ControllerFlowAPI,
  currentDate: Date = null,
): Promise<IParticipantStepsContext['participantSteps']> => {
  const { viewMode } = flowAPI.controllerConfig.wixCodeApi.window;
  const { participant } = await userProviderPropsMap({ flowAPI });
  const isJoinedParticipant =
    participant?.id &&
    participant?.transitions[0] &&
    userTypeHandlers.isJoinedAlready(participant?.transitions[0].state);

  let participantSteps: ListParticipantStepsResponse = {
    steps: [],
  };

  flowAPI.controllerConfig.setProps({
    isParticipantStepsLoading: true,
  });

  if (isJoinedParticipant) {
    const startDateOfParticipation = new Date(
      getRightDateFromBackend(participant.dateFrame.start),
    );
    const weekRange = getWeekRange(
      startDateOfParticipation,
      sureDateAfterSpecificDate(
        currentDate ? new Date(currentDate) : new Date(),
        startDateOfParticipation,
      ),
    );

    try {
      participantSteps = await participantAPI.listSteps({
        challengeId: getChallengeIdFromLocation(flowAPI),
        dateInterval: {
          start: weekRange.from ? format(weekRange.from, 'yyyy-MM-dd') : null,
          finish: weekRange.to
            ? format(addDays(weekRange.to, 1), 'yyyy-MM-dd')
            : null,
        },
        participantId: participant.id,
      });
    } catch (err) {
      console.error('[Challenge]: failed to get participant steps:', err);
      flowAPI.reportError(err);
      flowAPI.sentryMonitor.captureMessage(
        `[PARTICIPANTS LIST ERROR]: ${JSON.stringify(err)}`,
      );
    }
  }

  if (
    viewMode !== VIEW_MODE.Site &&
    !(
      participantSteps &&
      participantSteps.steps &&
      participantSteps.steps.length
    )
  ) {
    // mock data
    participantSteps = {
      steps: PARTICIPANT_STEPS as any,
    };
  }

  flowAPI.controllerConfig.setProps({
    isParticipantStepsLoading: false,
  });

  return participantSteps;
};

const updateParticipantStepStatus = (
  flowAPI: ControllerFlowAPI,
  steps: V1ParticipantStep[],
  stepId: string,
  state: ParticipantStepState,
): void => {
  steps.forEach((step) => {
    if (step.id === stepId) {
      if (!step.transitions) {
        step.transitions = [];
      }
      step.transitions.unshift({
        state,
        occurredAt: new Date(),
      });
    }
  });

  flowAPI.controllerConfig.setProps({
    participantSteps: { steps },
  });
};

export const participantStepsDataProviderPropsMap = memoize(async function ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IParticipantStepsContext> {
  handleUserLogin(flowAPI);

  return {
    participantSteps: await loadParticipantSteps(flowAPI),
    updateParticipantSteps: async (currentDate: Date = null) => {
      flowAPI.controllerConfig.setProps({
        participantSteps: await loadParticipantSteps(flowAPI, currentDate),
      });
    },
    isParticipantStepsLoading: false,
    updateParticipantStepStatus: async (steps, stepId, status) =>
      updateParticipantStepStatus(flowAPI, steps, stepId, status),
  };
});
