import { challengeAPI } from '@wix/challenges-web-api/dist/src/API';

export const getInvitationLink = async (
  challengeId: string,
  metaSiteId: string,
): Promise<string> => {
  let inviteLink = '';

  try {
    const {
      invitationLink: { url },
    } = await challengeAPI.getInvitationLink({
      challengeId,
    });

    inviteLink = url;
  } catch (e) {
    console.error('[Challenge Page]: failed to get invitation link:', e);
  }

  return (
    inviteLink || `https://apps.wix.com/place-invites/join-lp/${metaSiteId}`
  );
};
