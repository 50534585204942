import {
  I$WWrapper,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { createEventHandler } from '@wix/tpa-settings';
import { challengeSettings } from './Settings/challengeSettings';
import { challengeSettings as mobileChallengeSettings } from './Settings.mobile/challengeSettings';
import { ButtonState, ISettingsEvents } from './Widget/settingsEvents';
import { initApi } from '../../services/initApi';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/BI/interfaces';
import { settingsProviderPropsMap } from '../../contexts/Settings/settingsProviderPropsMap';
import { challengesListDataProviderPropsMap } from '../../contexts/ChallengesListDataProvider/challengesListDataProviderPropsMap';
import get from 'lodash/get';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const isMobile = flowAPI.isMobile();

  initApi(flowAPI);

  // settings panel pub/sub https://github.com/wix-private/tpa-settings#events-between-settings-panel-and-applications
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);
  // subscribe to event. Supports Typescript and events keys maybe only the keys of IEvents interface.
  settingsEventsHandler.on('buttonState', (value) => {
    setProps({
      buttonState: value,
    });
  });
  // subscribe on reset. When setting panel is closed
  settingsEventsHandler.onReset(() => {
    setProps({
      buttonState: ButtonState.Default,
    });
  });

  return {
    async pageReady() {
      const initialProps = await providersPropsMap({
        biSettings: {
          screenName: ScreenNames.ChallengeListWidget,
          preventSendChallengeIdFormLocation: true,
          getItemsCount: async () => {
            let challenges;
            try {
              challenges = await challengesListDataProviderPropsMap({
                flowAPI,
              });
            } catch (error) {
              console.error(
                '[challenges] Error getting challengesList on init BI',
                error,
              );
            }

            return get(challenges, 'challengesListData.totalCount') || 0;
          },
        },
        flowAPI,
        settingsConfig: isMobile ? mobileChallengeSettings : challengeSettings,
        enabledProviders: ['challengesListDataProvider', 'paidPlansProvider'],
      });

      try {
        flowAPI.controllerConfig.setProps({
          ...initialProps,
        });
      } catch (e) {
        console.error('[challenge list]: couldnt set initial props');
      }

      flowAPI.controllerConfig.setProps({
        editorLoaded: true,
      });
    },
    updateConfig($w: I$WWrapper, config: IControllerConfig) {
      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});
      setProps({
        ...settingsProviderPropsMap(
          config,
          isMobile ? mobileChallengeSettings : challengeSettings,
        ),
      });
    },
  };
};

export default createController;
