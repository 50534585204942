import {
  paidPlansBenefitsApi,
  IBenefitWithPlanInfo,
} from '@wix/challenges-web-api/dist/src/API';
import { IUserPaidPlan } from '@wix/challenges-web-library/dist/src/types';

const PAID_PLANS_PROXY_URL =
  'https://www.wix.com/_api/challenges-web-proxy/paid-plans-proxy';

export const loadUserPaidPlans = async instance => {
  try {
    const { plans }: { plans: IUserPaidPlan[] } = await (
      await fetch(PAID_PLANS_PROXY_URL, {
        method: 'GET',
        headers: {
          Authorization: instance,
        },
      })
    ).json();
    const paidPlansWithBenefits: IBenefitWithPlanInfo[] = await paidPlansBenefitsApi.listAllBenefits();

    if (paidPlansWithBenefits && paidPlansWithBenefits.length) {
      paidPlansWithBenefits.forEach(({ planInfo, benefit }) => {
        plans.forEach(plan => {
          if (planInfo.id === plan.id) {
            plan.challenges = benefit.resourceIds;
          }
        });
      });
    }

    return plans;
  } catch (err) {
    console.error('Failed fetching Paid Plans.');
    console.error(err);
  }
};
