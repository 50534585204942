import { IChallengeSettings } from './challengeSettings.types';

import {
  TextAlignment,
  ImageShape,
  ImageRatio,
  CropSelection,
} from '../../Settings/challengeSettings/challengeSettings.types';

export const challengeSettings: IChallengeSettings = {
  mobDisplayImage: {
    key: 'mobDisplayImage',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayDuration: {
    key: 'mobDisplayDuration',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayParticipants: {
    key: 'mobDisplayParticipants',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayDivider: {
    key: 'mobDisplayDivider',
    getDefaultValue() {
      return true;
    },
  },
  mobDisplayPrice: {
    key: 'mobDisplayPrice',
    getDefaultValue() {
      return true;
    },
  },
  mobTextAlignment: {
    key: 'mobTextAlignment',
    getDefaultValue() {
      return TextAlignment.Left;
    },
  },
  mobCardSpacing: {
    key: 'mobCardSpacing',
    getDefaultValue: () => 32,
  },
  mobImageShape: {
    key: 'mobImageShape',
    getDefaultValue() {
      return ImageShape.Rectangle;
    },
  },
  mobImageRatio: {
    key: 'mobImageRatio',
    getDefaultValue() {
      return ImageRatio.HD;
    },
  },
  mobCropSelection: {
    key: 'mobCropSelection',
    getDefaultValue() {
      return CropSelection.Middle;
    },
  },
  buttonText: {
    key: 'buttonText',
    getDefaultValue() {
      return 'challenge-card.view-button';
    },
  },
};
