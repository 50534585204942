import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import {
  ParticipantState,
  V1AccessType,
  V1Participant,
} from '@wix/ambassador-challenge-service-web/types';
import { challengeDataProviderPropsMap } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';
import { acceptInvite } from './acceptInvite';
import { sendRequestForOwnersApprove } from './sendRequestForOwnersApprove';
import { joinWithoutOwnerApprove } from './joinWithoutOwnerApprove';
import { getParticipant } from './getParticipant';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';

export const createParticipant = async ({
  timeZone,
  startDateFormatted,
  flowAPI,
  selectedPaymentOption,
}: {
  timeZone: string;
  startDateFormatted?: string;
  flowAPI: ControllerFlowAPI;
  selectedPaymentOption: SelectedPaymentOption;
}): Promise<V1Participant> => {
  try {
    const participant = await getParticipant(flowAPI);
    const participantTransitionState: ParticipantState | undefined =
      participant?.transitions?.length && participant?.transitions['0'].state;
    const { challengeData } = await challengeDataProviderPropsMap({
      flowAPI,
    });

    const challengeAccessType =
      challengeData.challenge.settings.accessRestrictions.accessType;

    switch (participantTransitionState) {
      case ParticipantState.INVITED:
        await acceptInvite(flowAPI, timeZone, startDateFormatted);
        return getParticipant(flowAPI);
      case ParticipantState.JOIN_REJECTED:
        await sendRequestForOwnersApprove(
          flowAPI,
          timeZone,
          startDateFormatted,
        );
        return getParticipant(flowAPI);
      case ParticipantState.PAYMENT_REQUESTED:
      case ParticipantState.PAYMENT_STARTED:
      case ParticipantState.JOIN_REQUESTED:
      case ParticipantState.JOINED:
      case ParticipantState.RUNNING:
      case ParticipantState.COMPLETED:
      case ParticipantState.FAILED:
        return participant;
      case ParticipantState.REMOVED:
      case ParticipantState.LEFT:
      default:
        if (challengeAccessType === V1AccessType.PRIVATE) {
          await sendRequestForOwnersApprove(
            flowAPI,
            timeZone,
            startDateFormatted,
          );
          return getParticipant(flowAPI);
        }
        await joinWithoutOwnerApprove(flowAPI, timeZone, startDateFormatted);
        return getParticipant(flowAPI);
    }
  } catch (err) {
    console.error('[Challenges]: Error at join challenge.', {
      flowAPI,
      selectedPaymentOption,
      startDateFormatted,
    });
  }
  return getParticipant(flowAPI);
};
