import {
  I$WWrapper,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
// import { id as widgetId } from './.component.json';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { settingsProviderPropsMap } from '../../contexts/Settings/settingsProviderPropsMap';
import { initApi } from '../../services/initApi';
import { ScreenNames } from '../../contexts/BI/interfaces';
import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { generalDataProviderPropsMap } from '../../contexts/GeneralDataProvider/generalDataProviderPropsMap';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;

  initApi(flowAPI);

  return {
    async pageReady() {
      try {
        const initialProps = {
          ...(await providersPropsMap({
            biSettings: {
              preventSendChallengeIdFormLocation:
                generalDataProviderPropsMap(flowAPI).viewMode === 'Editor',
              screenName: ScreenNames.ThankYouPage,
              getItemsCount: () => 1,
            },
            flowAPI,
            settingsConfig: {},
            enabledProviders: ['challengeDataProvider', 'inviteLinkProvider'],
          })),
        };

        setProps(initialProps);
      } catch (err) {
        console.error(err);
      }
    },
  };
};

export default createController;
