import { wrapper } from '@wix/challenges-web-api/dist/src/interfaces/generated/api';

import IChallenge = wrapper.com.wixpress.achievements.api.v1.Challenge;
import DurationUnit = wrapper.com.wixpress.achievements.api.v1.DurationUnit;
import PaymentMethod = wrapper.com.wixpress.achievements.api.v1.Challenge.Pricing.SinglePayment.PaymentMethod;
import AccessType = wrapper.com.wixpress.achievements.api.v1.AccessType;
import { V1ChallengeState } from '@wix/ambassador-challenge-service-web/types';

export const flexibleChallengeWeeks: IChallenge = {
  participantsSummary: {
    participantsNumber: 10,
  },
  id: '1',
  // @ts-ignore
  transitions: [{ state: [V1ChallengeState.PUBLISHED] }],
  settings: {
    description: {
      title: '3-Week Challenge with Price and 12 Participants',
      media: {},
    },
    accessRestrictions: {
      accessType: AccessType.PUBLIC,
      maxParticipants: 10,
    },
    timelineType: {
      flexible: {
        duration: {
          unit: DurationUnit.WEEKS,
          value: 3,
        },
      },
    },
    pricing: {
      singlePayment: {
        price: {
          currency: 'USD',
          amount: '123.01',
        },
        allowedMethods: [PaymentMethod.OFFLINE_PAYMENT],
      },
    },
  },
};

export const flexibleChallengeDay: IChallenge = {
  participantsSummary: {
    participantsNumber: 10,
  },
  id: '2',
  // @ts-ignore
  transitions: [{ state: [V1ChallengeState.PUBLISHED] }],
  settings: {
    description: {
      title: '3-Week Challenge with Price and 12 Participants',
      media: {},
    },
    accessRestrictions: {
      accessType: AccessType.PUBLIC,
      maxParticipants: 10,
    },
    timelineType: {
      flexible: {
        duration: {
          unit: DurationUnit.WEEKS,
          value: 3,
        },
      },
    },
    pricing: {
      paidPlans: [{ id: 'f2e85b46-014b-4c66-80f7-477d653014bb' }],
    },
  },
};

export const userPaidPlans = [
  {
    id: 'f2e85b46-014b-4c66-80f7-477d653014bb',
    roleId: 'f2e85b46-014b-4c66-80f7-477d653014bb',
    details: {
      name: 'Plan Name',
      description: 'Tagline',
      ribbon: '',
      benefits: ['Plan benefits'],
      disclaimer: '',
    },
    challenges: ['2'],
    paymentOptions: {
      price: {
        amount: '150',
        currency: 'USD',
      },
      recurring: false,
      validFor: {
        forever: false,
        period: {
          amount: 1,
          unit: 'MONTH',
        },
      },
    },
    visible: true,
    archived: false,
    primary: false,
    hasOrders: false,
    dateCreated: '2019-11-26T10:28:24.946Z',
    dateUpdated: '2019-11-26T10:28:24.946Z',
    slug: 'plan-name',
    template: false,
  },
];

const INITIAL_CHALLENGE: Omit<IChallenge, 'timelineType'> = {
  id: 'test',
  participantsSummary: {
    participantsNumber: 10,
  },
  // @ts-ignore
  transitions: [{ state: [V1ChallengeState.PUBLISHED] }],
  settings: {
    completionCriteria: {
      completedSteps: {
        all: true,
        minThreshold: {
          value: 10,
        },
      },
      completedMilestones: {
        minThreshold: {
          value: 10,
        },
        all: true,
      },
      scoredPoints: {
        value: 10,
      },
    },
    accessRestrictions: {
      maxParticipants: 10,
      accessType: AccessType.PUBLIC,
    },
  },
};

export const FLEXIBLE_CHALLENGE1: IChallenge = {
  ...INITIAL_CHALLENGE,
  // @ts-ignore
  mocked: true, // to check on challenge page in editor
  settings: {
    ...INITIAL_CHALLENGE.settings,
    description: {
      media: {
        image: {
          width: 400,
          url: 'media/a0a875_9849fd794f234a5c82ff357a3ffa7164~mv2.jpg',
          id: 'a0a875_9849fd794f234a5c82ff357a3ffa7164~mv2.jpg',
          height: 300,
        },
      },
      title: 'Run Your First 5k',
      details: '',
    },
    timelineType: {
      flexible: {
        duration: {
          unit: DurationUnit.DAYS,
          value: 21,
        },
      },
    },
  },
};

export const FLEXIBLE_CHALLENGE2: IChallenge = {
  ...INITIAL_CHALLENGE,
  settings: {
    ...INITIAL_CHALLENGE.settings,
    description: {
      media: {
        image: {
          width: 400,
          id: 'a0a875_94a2a4699c3c4df187c6cda6969855e4~mv2.jpg',
          url: 'media/a0a875_94a2a4699c3c4df187c6cda6969855e4~mv2.jpg',
          height: 300,
        },
      },
      title: 'Marathon Training',
      details: '',
    },
    timelineType: {
      flexible: {
        duration: {
          unit: DurationUnit.DAYS,
          value: 21,
        },
      },
    },
  },
};

export const FLEXIBLE_CHALLENGE3: IChallenge = {
  ...INITIAL_CHALLENGE,
  settings: {
    ...INITIAL_CHALLENGE.settings,
    description: {
      media: {
        image: {
          width: 400,
          id: 'a0a875_0a13877b9eb1427d835fb3257eecde25~mv2.jpg',
          url: 'media/a0a875_0a13877b9eb1427d835fb3257eecde25~mv2.jpg',
          height: 300,
        },
      },
      title: 'Meal for Runners',
      details: '',
    },
    timelineType: {
      flexible: {
        duration: {
          unit: DurationUnit.DAYS,
          value: 21,
        },
      },
    },
  },
};

export const CHALLENGES = [
  { challenge: FLEXIBLE_CHALLENGE1 },
  { challenge: FLEXIBLE_CHALLENGE2 },
  { challenge: FLEXIBLE_CHALLENGE3 },
];

const EDITORX_MEDIA = {
  MealForRunners: {
    image: {
      width: 1200,
      id: 'a0a875_ce06cede44414c9989603c9690070746~mv2.jpg',
      url: 'media/a0a875_ce06cede44414c9989603c9690070746~mv2.jpg',
      height: 1200,
    },
  },
  RunYourFirst: {
    image: {
      width: 400,
      id: 'a0a875_a6961ed3f19b44d994235720b4a3cf42~mv2.jpg',
      url: 'media/a0a875_a6961ed3f19b44d994235720b4a3cf42~mv2.jpg',
      height: 300,
    },
  },
  MarathonTraining: {
    image: {
      width: 1200,
      id: 'a0a875_2419a6dfc2a24ee9a003a63038f86dd3~mv2.jpg',
      url: 'media/a0a875_2419a6dfc2a24ee9a003a63038f86dd3~mv2.jpg',
      height: 1200,
    },
  },
};

export const CHALLENGES_EDITORX = [
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE3,
      settings: {
        ...FLEXIBLE_CHALLENGE3.settings,
        description: {
          ...FLEXIBLE_CHALLENGE3.settings.description,
          media: EDITORX_MEDIA.RunYourFirst,
        },
      },
    },
  },
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE3,
      settings: {
        ...FLEXIBLE_CHALLENGE3.settings,
        description: {
          ...FLEXIBLE_CHALLENGE3.settings.description,
          media: EDITORX_MEDIA.MarathonTraining,
        },
      },
    },
  },
  {
    challenge: {
      ...FLEXIBLE_CHALLENGE3,
      settings: {
        ...FLEXIBLE_CHALLENGE3.settings,
        description: {
          ...FLEXIBLE_CHALLENGE3.settings.description,
          media: EDITORX_MEDIA.MealForRunners,
        },
      },
    },
  },
];
