import { endOfWeek, getDay, startOfWeek, isAfter } from 'date-fns';

export const isCorrectStringDate = (
  date: string,
  separator = '/',
  reverse = false,
): Boolean => {
  const pattern = reverse
    ? `\\d\\d\\d\\d\\${separator}\\d\\d\\${separator}\\d\\d`
    : `\\d\\d\\${separator}\\d\\d\\${separator}\\d\\d\\d\\d`;

  return new RegExp(pattern).test(date);
};

export const getDateFromString = (
  date: string,
  separator = '/',
  reverse = false,
): Date => {
  const isCorrectDate = isCorrectStringDate(date, separator, reverse);
  let result = null;

  if (isCorrectDate) {
    const parsed = date.split(separator);
    const day = reverse ? parseInt(parsed[2], 10) : parseInt(parsed[0], 10);
    const month = parseInt(parsed[1], 10) - 1;
    const year = reverse ? parseInt(parsed[0], 10) : parseInt(parsed[2], 10);

    result = new Date(year, month, day);
  }

  return result;
};

export const getWeekRange = (
  startDate: Date,
  currentDate: Date,
): {
  from: Date;
  to: Date;
} => {
  const from = startOfWeek(currentDate, {
    weekStartsOn: getDay(startDate),
  });

  const to = endOfWeek(currentDate, {
    weekStartsOn: getDay(startDate),
  });

  return {
    from,
    to,
  };
};

export const sureDateAfterSpecificDate = (date: Date, dateToCompare: Date) => {
  return isAfter(date, dateToCompare) ? date : dateToCompare;
};

export const getRightDateFromBackend = (date: string): Date => {
  return getDateFromString(date, '-', true);
};
