import {
  I$WWrapper,
  IControllerConfig,
} from '@wix/native-components-infra/dist/src/types/types';
import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { createEventHandler } from '@wix/tpa-settings';
import { ScreenNames } from '../../contexts/BI/interfaces';
import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { settingsProviderPropsMap } from '../../contexts/Settings/settingsProviderPropsMap';
import { initApi } from '../../services/initApi';
import { challengeSettings } from './Settings/challengeSettings';
import { challengeSettings as mobChallengeSettings } from './Settings.mobile/challengeSettings';
import { ISettingsEvents } from './Widget/settingsEvents';
import { ButtonState } from '../ChallengesList/Widget/settingsEvents';
import { experimentsProviderPropsMap } from '../../contexts/Experiments/experimentsProviderPropsMap';
import { Challenges } from '../../editor/types/Experiments';
import { biProviderPropsMap } from '../../contexts/BI/BIProviderPropsMap';
import { isParticipantPage } from '../../selectors/participants';
import { renderSeoMetatags } from '../../services/SeoMetatags';

const createController: CreateControllerFn = async ({
  controllerConfig,
  flowAPI,
}: ControllerParams) => {
  const { setProps } = flowAPI.controllerConfig;
  const publicData = controllerConfig.config.publicData.COMPONENT || {};
  const settingsEventsHandler = createEventHandler<ISettingsEvents>(publicData);
  const isMobile = flowAPI.isMobile();
  let isMemberFlowEnabled = false;
  let isEnabledSEOTagsRender = false;

  initApi(flowAPI);

  try {
    const experiments = await experimentsProviderPropsMap(flowAPI);
    isMemberFlowEnabled = experiments.enabled(Challenges.paymentPage);
    isEnabledSEOTagsRender = experiments.enabled(Challenges.renderSeoTags);
  } catch (e) {
    console.error(
      '[challenges page] Failed to get experiments from controller',
      e,
    );
  }

  // a little weird solution
  let _buttonState: string = ButtonState.Default;
  settingsEventsHandler.on('buttonState', (value) => {
    _buttonState = value;
  });
  settingsEventsHandler.onReset(() => {
    _buttonState = ButtonState.Default;
  });

  return {
    async pageReady() {
      setProps({
        isMemberFlowEnabled,
      });
      try {
        const initialProps = {
          ...(await providersPropsMap({
            flowAPI,
            settingsConfig: isMobile ? mobChallengeSettings : challengeSettings,
            enabledProviders: [
              'challengeDataProvider',
              'inviteLinkProvider',
              'paidPlansProvider',
              'participantStepsDataProvider',
              'resolveStepDataProvider',
            ],
          })),
          ...{
            isLoading: false,
          },
        };

        /**
         * Need to set right screenName is case of joined participant.
         * Should wait for other providers first because we use some additional data here for the condition.
         */

        const biProvider = biProviderPropsMap({
          biSettings: {
            preventSendChallengeIdFormLocation:
              initialProps.viewMode === 'Editor',
            screenName: isParticipantPage(initialProps as any)
              ? ScreenNames.ChallengePageForParticipant
              : ScreenNames.ChallengePage,
            getItemsCount: () => 1,
          },
          flowAPI,
        });

        Object.entries(biProvider).forEach(([key, value]) => {
          initialProps[key] = value;
        });

        if (isEnabledSEOTagsRender) {
          await renderSeoMetatags(
            flowAPI,
            initialProps.challengeData?.challenge,
          );
        }

        setProps({ ...initialProps });
      } catch (err) {
        console.error(err);
      }
    },
    updateConfig($w: I$WWrapper, config: IControllerConfig) {
      const newSettings = settingsProviderPropsMap(
        config,
        isMobile ? mobChallengeSettings : challengeSettings,
      );

      newSettings.settings.buttonState = _buttonState;

      // notify (events should be fired)
      settingsEventsHandler.notify(config.publicData.COMPONENT || {});

      setProps({
        ...newSettings,
      });
    },
  };
};

export default createController;
