import initSchemaLogger, {
  GUID,
  Logger,
} from '@wix/bi-logger-challenges-member-web';
import { VIEW_MODE } from '../../config/constants';
import { getChallengeIdFromLocation } from '../Location/locationProviderPropsMap';
import { BIProviderPropsMap, IBiSettings } from './interfaces';
import uuid from 'uuid';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';

let bi: BIProviderPropsMap = null;

export const biProviderPropsMap = ({
  biSettings,
  flowAPI,
}: {
  biSettings?: IBiSettings;
  flowAPI: ControllerFlowAPI;
}): BIProviderPropsMap => {
  if (bi) {
    return bi;
  }

  const { viewMode } = flowAPI.controllerConfig.wixCodeApi.window;

  const { platformAPIs } = flowAPI.controllerConfig;
  const { ownerId } = platformAPIs.bi;
  const generaInfo = generalDataProviderPropsMap(flowAPI);

  const biLogger: Logger = initSchemaLogger(platformAPIs.biLoggerFactory())({
    defaults: {
      origin: generaInfo.viewMode,
      _uuid: ownerId,
    },
  });
  const startTimeOfRender = Date.now();

  bi = {
    startTimeOfRender,
    memberWebAppButtonClick(options) {
      return biLogger.memberWebAppButtonClick({
        screenName: biSettings.screenName,
        challengeId: biSettings.preventSendChallengeIdFormLocation
          ? undefined
          : getChallengeIdFromLocation(flowAPI),
        ...options,
      });
    },
    beforeAndAfterAction: async (actionType, fn) => {
      const actionId = uuid();
      await biLogger.memberWebAppOnBeforeAction({
        actionId,
        actionType,
        screenName: biSettings.screenName,
      });
      const response = await fn(actionId);
      await biLogger.memberWebOnAfterAction({
        actionId,
        actionType,
        screenName: biSettings.screenName,
      });
      return response;
    },
    screenOpen: async () => {
      await biLogger.memberWebAppScreenOpen({
        screenName: biSettings.screenName,
        challengeId: biSettings.preventSendChallengeIdFormLocation
          ? undefined
          : getChallengeIdFromLocation(flowAPI),
      });
    },
    tabOpen: async (options) => {
      await biLogger.memberWebAppTabClick({
        screenName: biSettings.screenName,
        challengeId: biSettings.preventSendChallengeIdFormLocation
          ? undefined
          : getChallengeIdFromLocation(flowAPI),
        ...options,
      });
    },
    reportSSRIsRendered: async () => {
      if (viewMode === VIEW_MODE.Site) {
        const now = new Date().getTime();
        await biLogger.memberWebWidgetShown({
          timeSpent: startTimeOfRender ? now - startTimeOfRender : 0,
          screenName: biSettings.screenName,
          itemsCount: await biSettings.getItemsCount(flowAPI.controllerConfig),
          challengeId: biSettings.preventSendChallengeIdFormLocation
            ? undefined
            : getChallengeIdFromLocation(flowAPI),
        });
      }
    },
  };

  return bi;
};
