import { ISettingsParam, ISettingsParams } from '@wix/tpa-settings';

export const enum TextAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export const enum ImageRatio {
  HD = 'hd',
  Standard = 'standard',
  Square = 'square',
}
export const enum CropSelection {
  TopLeft = 'TOP_LEFT',
  top = 'TOP',
  TopRight = 'TOP_RIGHT',
  Left = 'LEFT',
  Middle = 'MIDDLE',
  Right = 'RIGHT',
  BottomLeft = 'BOTTOM_LEFT',
  Bottom = 'BOTTOM',
  BottomRight = 'BOTTOM_RIGHT',
}

export type IChallengeSettings = ISettingsParams<{
  headerTextAlignment: ISettingsParam<TextAlignment>;
  contentTextAlignment: ISettingsParam<TextAlignment>;
  imageRatio: ISettingsParam<ImageRatio>;
  cropSelection: ISettingsParam<CropSelection>;
  displayHeaderDate: ISettingsParam<boolean>;
  displayHeaderPrice: ISettingsParam<boolean>;
  displayHeaderImage: ISettingsParam<boolean>;
  displayChallengeDuration: ISettingsParam<boolean>;
  displayChallengeSteps: ISettingsParam<boolean>;
  displayChallengeParticipants: ISettingsParam<boolean>;
  displayOwner: ISettingsParam<boolean>;
  displayOneApp: ISettingsParam<boolean>;
  displayDivider: ISettingsParam<boolean>;
}>;
