import { approvalAPI } from '@wix/challenges-web-api/dist/src/API';
import { getChallengeIdFromLocation } from '../../Location/locationProviderPropsMap';
import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { ApprovalRequestResolutionStatus } from '@wix/ambassador-challenge-service-web/types';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';

export async function acceptInvite(
  flowAPI: ControllerFlowAPI,
  timeZone: string,
  startDate: string,
) {
  const approvals = await approvalAPI.listApprovalRequests({
    challengeId: getChallengeIdFromLocation(flowAPI),
    resolverId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
    includeResolved: false,
  });
  const approvalRequestId =
    approvals?.requests['0'] && approvals?.requests['0'].id;

  if (approvalRequestId) {
    const bi = biProviderPropsMap({
      biSettings: {
        screenName: ScreenNames.ChallengePage,
        getItemsCount: () => 1,
      },
      flowAPI,
    });
    await bi.beforeAndAfterAction(ActionTypes.CHALLENGE_JOIN, (actionId) =>
      approvalAPI.resolveApprovalRequest({
        actionId,
        memberTimeZone: timeZone,
        requestId: approvalRequestId,
        resolutionStatus: ApprovalRequestResolutionStatus.ACCEPTED,
        startDate,
      }),
    );
  } else {
    console.error("[Challenges]: Can't find approval request id.", {
      flowAPI,
      startDate,
    });
    return;
  }
}
