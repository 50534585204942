import i18nFactory from '../config/i18n';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { translationProviderPropsMap } from '../contexts/main/mappings/translationProvidersPropsMappings';
import { i18n } from 'i18next';

export async function getI18nInWorker(
  flowAPI: ControllerFlowAPI,
): Promise<i18n> {
  const localization = await translationProviderPropsMap(flowAPI);
  return i18nFactory({
    language: localization.language,
    translations: localization.translations,
  });
}
