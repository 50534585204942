import { challengeDataProviderPropsMap } from '../ChallengeDataProvider/challengeDataProviderPropsMap';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { translationProviderPropsMap } from '../main/mappings/translationProvidersPropsMappings';
import { biProviderPropsMap } from '../BI/BIProviderPropsMap';
import { ActionTypes } from '../BI/interfaces';
import { ParticipantState } from '@wix/ambassador-challenge-service-web/types';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';

export interface IPaymentProviderProps {
  appId: string;
  instanceId: string;
  orderId: string;
  language: string;
  ownerId: string;
}

async function getOrderId(flowAPI: ControllerFlowAPI): Promise<string> {
  const userData = await userProviderPropsMap({
    flowAPI,
  });
  const participant = userData.participant;

  if (participant) {
    switch (participant.transitions[0].state) {
      case ParticipantState.PAYMENT_STARTED:
        return participant.orderIds[0];
      default:
      case ParticipantState.PAYMENT_REQUESTED:
        return '';
    }
  }
  return '';
}

export async function paymentProviderPropsMap({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IPaymentProviderProps> {
  const orderId = await getOrderId(flowAPI);
  const { ownerId } = flowAPI.controllerConfig.platformAPIs.bi;
  const translationInfo = await translationProviderPropsMap(flowAPI);

  return {
    appId: flowAPI.controllerConfig.appParams.appDefinitionId,
    instanceId: flowAPI.controllerConfig.appParams.instanceId,
    orderId,
    language: translationInfo.language,
    ownerId,
  };
}
