import { ISettingsProviderProps } from './SettingsProvider';
import { getSettingsValues, ISettingsParams } from '@wix/tpa-settings';
import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';

const MOB_PREFIX = 'mob';

const lowerCaseFirstLetter = (str: string) => {
  return str[0].toLowerCase() + str.slice(1);
};

const removePrefix = (settings: { [key: string]: any }) => {
  const processedList = Object.keys(settings).map((key) => {
    if (key.search(MOB_PREFIX) === 0) {
      return {
        [lowerCaseFirstLetter(key.replace(MOB_PREFIX, ''))]: settings[key],
      };
    }

    return {
      [key]: settings[key],
    };
  });

  return Object.assign({}, ...processedList);
};

export function settingsProviderPropsMap<T extends ISettingsParams>(
  config: IControllerConfig,
  settingsConfig: T,
): ISettingsProviderProps {
  const values = getSettingsValues(
    config.publicData.COMPONENT || ({} as any),
    settingsConfig,
  );

  const unifiedValues = removePrefix(values);

  return {
    settings: unifiedValues,
  };
}
