import { navigateToPaidPlans } from '../../../services/navigateToPaidPlans';
import { Pages } from '../../Location/LocationContext';
import { SelectedPaymentOption } from '../../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { paidPlansPropsMap } from '../../PaidPlans/paidPlansPropsMap';
import { getChallengePaidPlans } from '@wix/challenges-web-library/dist/src';
import {
  getChallengeIdFromLocation,
  locationProviderPropsMap,
} from '../../Location/locationProviderPropsMap';
import { getI18nInWorker } from '../../../services/translateInWorker';
import { ActionTypes } from '../../BI/interfaces';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { getPaymentType } from './userContextHelpers';
import { getParticipant } from './getParticipant';
import userTypeHandlers from './userTypeHandlers';

export async function payForChallenge(
  flowAPI: ControllerFlowAPI,
  paymentOption: SelectedPaymentOption,
  participantId: string,
  startDate: string,
  timeZone: string,
): Promise<{ userJoined: boolean }> {
  const location = locationProviderPropsMap(flowAPI);
  const challengeId = getChallengeIdFromLocation(flowAPI);

  const bi = biProviderPropsMap({ flowAPI });
  await bi.beforeAndAfterAction(
    ActionTypes.CREATE_PAYMENT_ORDER_ID,
    (actionId) =>
      participantAPI.createPaymentOrder({
        participantId,
        challengeId,
        startDate,
        paymentType: getPaymentType(paymentOption),
        timeZone,
        actionId,
      }),
  );

  if (paymentOption === SelectedPaymentOption.PaidPlans) {
    //user can be joined during order creation if plan is already purchased
    const participant = await getParticipant(flowAPI);
    if (userTypeHandlers.isJoinedAlready(participant.transitions[0].state)) {
      return { userJoined: true };
    }
  }

  if (paymentOption === SelectedPaymentOption.SinglePayment) {
    location.goToPage({
      challengeId,
      pageId: Pages.Payment,
    });
    return { userJoined: false };
  }

  if (paymentOption === SelectedPaymentOption.PaidPlans) {
    const paidPlans = await paidPlansPropsMap({ flowAPI });

    const challengePaidPlans = getChallengePaidPlans(
      challengeId,
      paidPlans.userPaidPlans,
    );

    const i18n = await getI18nInWorker(flowAPI);

    await navigateToPaidPlans({
      pageId: Pages.Details,
      url: challengeId,
      planIds: challengePaidPlans.map((paidPlan) => paidPlan.id),
      titleText: i18n.t(
        'pricing.payment-option.subscription.paid-plan.thank-you.title',
      ),
      buttonText: i18n.t(
        'pricing.payment-option.subscription.paid-plan.thank-you.button-title',
      ),
      contentText: i18n.t(
        'pricing.payment-option.subscription.paid-plan.thank-you.content',
      ),
      wixCodeApi: flowAPI.controllerConfig.wixCodeApi,
    });
    return { userJoined: false };
  }
}
