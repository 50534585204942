import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import { asyncMessagesLoader } from '../../../config/i18n';
import memoize from 'lodash/memoize';
import { experimentsProviderPropsMap } from '../../Experiments/experimentsProviderPropsMap';
import { Challenges } from '../../../editor/types/Experiments';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';

export interface ITranslationProviderProps {
  language: string;
  translations: any;
}

function getSiteLanguage({ wixCodeApi }: IWidgetControllerConfig): string {
  if (wixCodeApi.window.multilingual.isEnabled) {
    return wixCodeApi.window.multilingual.currentLanguage;
  }

  // NOTE: language can be null (see WEED-18001)
  return wixCodeApi.site.language || 'en';
}

export const translationProviderPropsMap = memoize(async function (
  flowAPI?: ControllerFlowAPI,
): Promise<ITranslationProviderProps> {
  const experiments = await experimentsProviderPropsMap(flowAPI);
  const localizationEnabled = experiments.enabled(
    Challenges.enableLocalization,
  );
  const language = getSiteLanguage(flowAPI.controllerConfig);
  const translations = await asyncMessagesLoader(
    localizationEnabled ? language : 'en',
  );

  return {
    language,
    translations,
  };
});
