import { paidPlansPropsMap } from '../../PaidPlans/paidPlansPropsMap';
import { getChallengeEligiblePaidPlans } from '../../../components/ChallengesPage/Widget/components/Pricing/subscriptionTitle/getChallengeEligiblePaidPlans';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { getChallengeIdFromLocation } from '../../Location/locationProviderPropsMap';

/**
 * we need to abort join flow after login in case when user already has eligible paid plan
 */
export async function isNeedToAbortJoinFlowAfterLogin(
  flowAPI: ControllerFlowAPI,
): Promise<boolean> {
  const challengeId = getChallengeIdFromLocation(flowAPI);
  const paidPlansProvider = await paidPlansPropsMap({ flowAPI });
  await paidPlansProvider.updatePromise;

  const challengeEligiblePaidPlan = getChallengeEligiblePaidPlans(
    paidPlansProvider.eligiblePlans,
    paidPlansProvider.userPaidPlans,
    challengeId,
  );

  return Boolean(challengeEligiblePaidPlan.length);
}
