import memoize from 'lodash/memoize';

import { challengeAPI } from '@wix/challenges-web-api/dist/src/API';
import {
  ListChallengesResponse,
  V1ChallengeState,
} from '@wix/ambassador-challenge-service-web/types';
import { VIEW_MODE } from '../../config/constants';
import {
  CHALLENGES,
  CHALLENGES_EDITORX,
} from '../../components/ChallengesList/Widget/mocks';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';

export interface IChallengesListDataProps {
  challengesListData: ListChallengesResponse;
}

async function getChallengesList(memberId: string) {
  return challengeAPI.listChallenges({
    memberId,
    paging: {
      limit: 100,
    },
    states: [V1ChallengeState.PUBLISHED, V1ChallengeState.FINISHED],
  });
}

async function handleUserLogin(flowAPI: ControllerFlowAPI) {
  flowAPI.controllerConfig.wixCodeApi.user.onLogin(async (user) => {
    let challenges;

    try {
      challenges = await getChallengesList(user.id);
    } catch (e) {
      console.error('[Challenge list]: failed to get challenges:', e);
    }

    flowAPI.controllerConfig.setProps({
      challengesListData: {
        ...challenges,
      },
    });
  });
}

const STORAGE_FIELD = 'CHALLENGES_LIST';

export const challengesListDataProviderPropsMap = memoize(async function ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IChallengesListDataProps> {
  const { viewMode } = flowAPI.controllerConfig.wixCodeApi.window;
  const userData = await userProviderPropsMap({ flowAPI });
  const memberId = userData.user.id;
  const isEditorX = !!flowAPI.controllerConfig.config?.style?.styleParams
    ?.booleans?.responsive;

  let challenges;

  await handleUserLogin(flowAPI);

  if (
    flowAPI.controllerConfig.platformAPIs.storage.memory &&
    flowAPI.controllerConfig.platformAPIs.storage.memory.getItem
  ) {
    try {
      const tmpList = flowAPI.controllerConfig.platformAPIs.storage.memory.getItem(
        STORAGE_FIELD,
      );
      challenges = tmpList ? JSON.parse(tmpList) : null;
    } catch (e) {
      console.error(
        '[CHALLENGES]: Failed to get storage data for challenges list',
      );
      flowAPI.reportError && flowAPI.reportError(e);
      flowAPI.sentryMonitor.captureMessage(
        `[FAILED] challenges list get to storage config: ${JSON.stringify(
          flowAPI,
        )}`,
      );
    }
  }

  if (!challenges) {
    try {
      challenges = await getChallengesList(memberId);

      if (
        !flowAPI.inEditor &&
        flowAPI.controllerConfig.platformAPIs.storage.memory &&
        flowAPI.controllerConfig.platformAPIs.storage.memory.setItem
      ) {
        flowAPI.controllerConfig.platformAPIs.storage.memory.setItem(
          STORAGE_FIELD,
          JSON.stringify(challenges),
        );
      }
    } catch (e) {
      console.error('[Challenge list]: failed to get challenges:', e);
      flowAPI.reportError(e);
      flowAPI.sentryMonitor.captureMessage(
        `[CHALLENGE LIST MEMORY ERROR]: ${JSON.stringify(e)}`,
      );
    }
  }

  if (viewMode !== VIEW_MODE.Site && (!challenges || !challenges.totalCount)) {
    challenges = {
      memberChallenges: isEditorX ? CHALLENGES_EDITORX : CHALLENGES,
      totalCount: isEditorX ? CHALLENGES_EDITORX.length : CHALLENGES.length,
    };
  }

  return {
    challengesListData: {
      ...challenges,
    },
  };
});
