import { ActionTypes, ScreenNames } from '../../BI/interfaces';
import { participantAPI } from '@wix/challenges-web-api/dist/src/API';
import { biProviderPropsMap } from '../../BI/BIProviderPropsMap';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { challengeDataProviderPropsMap } from '../../ChallengeDataProvider/challengeDataProviderPropsMap';

export async function sendRequestForOwnersApprove(
  flowAPI: ControllerFlowAPI,
  timeZone: string,
  startDate,
) {
  const bi = biProviderPropsMap({
    biSettings: {
      screenName: ScreenNames.ChallengePage,
      getItemsCount: () => 1,
    },
    flowAPI,
  });
  const { challengeData } = await challengeDataProviderPropsMap({
    flowAPI,
  });
  await bi.beforeAndAfterAction(ActionTypes.JOIN_REQUEST_CREATE, (actionId) =>
    participantAPI.createJoinRequest({
      challengeId: challengeData.challenge.id,
      memberId: flowAPI.controllerConfig.wixCodeApi.user.currentUser.id,
      timeZone,
      startDate,
      actionId,
    }),
  );
}
