import { IUserProviderProps } from './UserProvider';
import {
  getUserFromConfig,
  getUserType,
  handleUserLogin,
  promptLogin,
} from './helpers/userContextHelpers';
import userTypeHandlers from './helpers/userTypeHandlers';
import memoize from 'lodash/memoize';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { SelectedPaymentOption } from '../../components/ChallengesPage/Widget/components/Pricing/interfaces';
import { getParticipant } from './helpers/getParticipant';
import { joinToChallenge } from './helpers/joinUserToChallenge';
import { cancelInvite } from './helpers/cancelInvite';
import { leaveTheChallenge } from './helpers/leaveTheChallenge';

export const userProviderPropsMap = memoize(async function ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IUserProviderProps> {
  const user = getUserFromConfig(flowAPI.controllerConfig);
  const participant = await getParticipant(flowAPI);

  await handleUserLogin(flowAPI);

  return {
    user,
    userType: getUserType(user, participant),
    participant,
    async promptLogin(): Promise<any> {
      return promptLogin(flowAPI.controllerConfig);
    },
    async join(
      selectedPaymentOption: SelectedPaymentOption,
      startDate?: string,
    ): Promise<void> {
      return joinToChallenge(flowAPI, selectedPaymentOption, startDate);
    },
    userTypeHandlers,
    async cancelJoinRequest(): Promise<void> {
      return cancelInvite(flowAPI);
    },
    async leaveTheChallenge(participantId: string): Promise<void> {
      return leaveTheChallenge(flowAPI, participantId);
    },
  };
});
