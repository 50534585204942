import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { IPaidPlansProviderProps } from './interfaces';
import { Challenges } from '../../editor/types/Experiments';
import { loadUserPaidPlans } from '../../services/loadPaidPlans';
import { experimentsProviderPropsMap } from '../Experiments/experimentsProviderPropsMap';
import { getUserEligiblePlans } from './helpers/getUserEligiblePlans';

let paidPlans = null;

export async function paidPlansPropsMap({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): Promise<IPaidPlansProviderProps> {
  if (paidPlans) {
    return paidPlans;
  }

  const experiments = await experimentsProviderPropsMap(flowAPI);

  const userPaidPlans = experiments.disabled(Challenges.arePaidPlansEnabled)
    ? []
    : await loadUserPaidPlans(flowAPI.controllerConfig.appParams.instance);

  if (userPaidPlans) {
    flowAPI.controllerConfig.wixCodeApi.user.onLogin(() => {
      paidPlans.updatePromise = (async () => {
        paidPlans.userPaidPlans = await loadUserPaidPlans(
          flowAPI.controllerConfig.appParams.instance,
        );
        paidPlans.eligiblePlans = await getUserEligiblePlans(flowAPI);
        flowAPI.controllerConfig.setProps(paidPlans);
      })();
    });
  }

  paidPlans = {
    userPaidPlans,
    eligiblePlans: await getUserEligiblePlans(flowAPI),
    updatePromise: Promise.resolve(),
  };

  return paidPlans;
}
