import {
  setCommonHeaders,
  setChallengesServiceApiBaseUrl,
  paidPlansBenefitsApi,
} from '@wix/challenges-web-api/dist/src/API';
import { generalDataProviderPropsMap } from '../contexts/GeneralDataProvider/generalDataProviderPropsMap';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';

export function initApi(flowAPI: ControllerFlowAPI) {
  const generalData = generalDataProviderPropsMap(flowAPI);
  if (
    generalData.viewMode !== 'Site' ||
    generalData.renderingEnv === 'backend'
  ) {
    setChallengesServiceApiBaseUrl(
      'https://www.wix.com/_api/challenge-service-web',
    );
  }

  paidPlansBenefitsApi.setBaseUrl(
    'https://www.wix.com/_api/challenges-web-proxy/pricing-benefits-proxy',
  );

  paidPlansBenefitsApi.setHeaders({
    Authorization: flowAPI.controllerConfig.appParams.instance,
  });

  setCommonHeaders({
    Authorization: flowAPI.controllerConfig.appParams.instance,
  });

  flowAPI.controllerConfig.wixCodeApi.site.onInstanceChanged(({ instance }) => {
    setCommonHeaders({ Authorization: instance });
  });
}
