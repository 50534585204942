import {
  getChallengePaidPlans,
  IUserPaidPlan,
} from '@wix/challenges-web-library/dist/src';
import { MemberOrdersByPlan } from '@wix/ambassador-pricing-plan-benefits-server/types';

export function getChallengeEligiblePaidPlans(
  eligiblePaidPlans: MemberOrdersByPlan[],
  userPaidPlans: IUserPaidPlan[],
  challengeId: string,
): IUserPaidPlan[] {
  const challengePaidPlans = getChallengePaidPlans(challengeId, userPaidPlans);
  if (!challengePaidPlans.length || !eligiblePaidPlans.length) {
    return [];
  }

  return challengePaidPlans.filter(paidPlan =>
    eligiblePaidPlans.some(
      eligiblePaidPlan => eligiblePaidPlan.planInfo.id === paidPlan.id,
    ),
  );
}
