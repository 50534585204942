import { IProvidersProps } from './intefaces';
import { translationProviderPropsMap } from './mappings/translationProvidersPropsMappings';
import { biProviderPropsMap } from '../BI/BIProviderPropsMap';
import { settingsProviderPropsMap } from '../Settings/settingsProviderPropsMap';
import { ISettingsParams } from '@wix/tpa-settings';
import { userProviderPropsMap } from '../User/userProviderPropsMap';
import { locationProviderPropsMap } from '../Location/locationProviderPropsMap';
import { challengeDataProviderPropsMap } from '../ChallengeDataProvider/challengeDataProviderPropsMap';
import { paymentProviderPropsMap } from '../Payment/paymentProviderPropsMap';
import { generalDataProviderPropsMap } from '../GeneralDataProvider/generalDataProviderPropsMap';
import { challengesListDataProviderPropsMap } from '../ChallengesListDataProvider/challengesListDataProviderPropsMap';
import { IBiSettings } from '../BI/interfaces';
import { experimentsProviderPropsMap } from '../Experiments/experimentsProviderPropsMap';
import { inviteLinkPropsMap } from '../InviteLink/inviteLinkPropsMap';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/flow-api/ViewerScript';
import { paidPlansPropsMap } from '../PaidPlans/paidPlansPropsMap';
import { participantStepsDataProviderPropsMap } from '../ParticipantStepsDataProvider/ParticipantStepsDataProviderPropsMap';
import { resolveStepDataProviderPropsMap } from '../ResolveStep/ResolveStepDataProviderPropsMap';

export type ToggleableProviders =
  | 'inviteLinkProvider'
  | 'paymentProvider'
  | 'challengeDataProvider'
  | 'challengesListDataProvider'
  | 'paidPlansProvider'
  | 'participantStepsDataProvider'
  | 'resolveStepDataProvider';

export const providers: Record<
  ToggleableProviders,
  ({ flowAPI: ControllerFlowAPI }) => Promise<any>
> = {
  paymentProvider: paymentProviderPropsMap,
  challengeDataProvider: challengeDataProviderPropsMap,
  challengesListDataProvider: challengesListDataProviderPropsMap,
  inviteLinkProvider: inviteLinkPropsMap,
  paidPlansProvider: paidPlansPropsMap,
  participantStepsDataProvider: participantStepsDataProviderPropsMap,
  resolveStepDataProvider: resolveStepDataProviderPropsMap,
};
interface IRootProviderPropsMap<T extends ISettingsParams> {
  settingsConfig: T;
  biSettings?: IBiSettings;
  enabledProviders: ToggleableProviders[];
  flowAPI: ControllerFlowAPI;
}

export async function providersPropsMap<T extends ISettingsParams>(
  options: IRootProviderPropsMap<T>,
): Promise<IProvidersProps> {
  const { settingsConfig, enabledProviders, biSettings, flowAPI } = options;

  const providersProps = {
    ...(biSettings ? biProviderPropsMap({ biSettings, flowAPI }) : {}),
    ...generalDataProviderPropsMap(flowAPI),
    ...locationProviderPropsMap(flowAPI),
    ...settingsProviderPropsMap(
      flowAPI.controllerConfig.config,
      settingsConfig,
    ),
  };

  const asyncProviders: Promise<any>[] = [
    userProviderPropsMap({ flowAPI }),
    experimentsProviderPropsMap(flowAPI),
    translationProviderPropsMap(flowAPI),
  ];

  enabledProviders.forEach((providerName) => {
    asyncProviders.push(providers[providerName]({ flowAPI }));
  });

  const data = await Promise.all(asyncProviders);
  data.forEach((providerData) => {
    Object.entries(providerData).forEach(([key, value]) => {
      providersProps[key] = value;
    });
  });

  return providersProps as any;
}
